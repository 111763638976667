import logo from './logo.svg';
import './App.css';
import Navigation from './component/Navigation';
import { Route, Routes } from 'react-router-dom';
import Login from './component/Login'
import Home from './component/Home'
function App() {
  return (
    <div className="App">
      <Navigation></Navigation>
      <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/login" element={<Login />} />
        </Routes>
    </div>

  );
}

export default App;
